import { Link } from "gatsby"
import React from "react"

const HeaderBuero = ({ siteTitle }) => (
  <div className="header-nav left">
    <ul>
      <li><Link to="/buero">Das Büro</Link></li>
      <li><Link to="/buero/philosophie">Philosophie</Link></li>
      <li><Link to="/buero/geschichte">Geschichte</Link></li>
      <li><Link to="/buero/partner">Partner</Link></li>
      <li><Link to="/buero/mitarbeiter">Mitarbeiter</Link></li>
    </ul>
  </div>
)

export default HeaderBuero
