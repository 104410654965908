import React from "react"
import { useStaticQuery, Link } from "gatsby"
import Img from "gatsby-image"
import { useEmployees } from "../hooks/mitarbeiter"

function ThumbsMitarbeiter() {
  const data = useEmployees()

  console.log(data)

  return (
    <div className="grid-mitarbeiter">
    { data.map(({ node }, index) => (
      <figure key={index}>
        <div>
          <Link to={`/buero/mitarbeiter/${node.slug}`}><Img fluid={ node.pictureprev.localFile.childImageSharp.fluid } style={{height: '150px'}} />
          <div className="grid-mitarbeiter-desc">{ node.name }</div>
          </Link>  
        </div>
      </figure>
    ))}   
    </div>
  )
}

export default ThumbsMitarbeiter

