import { useStaticQuery, graphql } from "gatsby"

export const useEmployees = () => {
  const { allStrapiEmployee } = useStaticQuery(
  graphql`
    query {
      allStrapiEmployee(filter: {partner: {eq: false}}) {
        edges {
          node {
            slug
            name
            pictureprev {
              localFile {
                childImageSharp {
                  fluid(maxHeight: 470) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  `
  )

  return allStrapiEmployee.edges
}