import React from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import Reactmarkdown from "react-markdown"

import Layout from "../components/layout"
import SEO from "../components/seo"
import HeaderBuero from "../components/header_buero"
import Employees from "../components/thumbs-mitarbeiter"

const EmployeeTemplate = ({ data }) => {
  const employee = data.strapiEmployee

  return (
    <Layout>
    <SEO title={employee.name} />
    <HeaderBuero />

    <Img fluid={ employee.picture.localFile.childImageSharp.fixed } style={{ height: '550px' }} className="heroimage" />

    <Reactmarkdown>{ employee.desc }</Reactmarkdown>

    <Employees />

  </Layout>
  )
}

export default EmployeeTemplate

export const query = graphql`
  query EmployeeSingleQuery($slug: String!) {
    strapiEmployee(slug: {eq: $slug}) {
      name
      desc
      picture {
        localFile {
          childImageSharp {
            fixed(height: 550) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      } 
    }
  }
`